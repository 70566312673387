import ApiOfinans from "../../services/apiOfinans";

export default {
  state: () => ({}),
  mutations: {},
  actions: {
    async MPAY_CONVERT(state, params) {
      return await ApiOfinans.post("miracle-pay/convert", params);
    }
  },
  namespaced: true
};
