// USA
export const locale = {
  TRANSLATOR: {
    SELECT: "Dil Seçin"
  },
  SLOGAN: "Ödeme Almanın Yeni Yolu",
  MENU: {
    NEW: "Yeni",
    EDIT: "Düzenle",
    ACTIONS: "İşlemler",
    CREATE_POST: "Yeni yazı oluştur",
    PAGES: "Sayfalar",
    FEATURES: "Özellikler",
    APPS: "Uygulamalar",
    GREET: "Merhaba",
    BACK: "Geri"
  },
  ROUTES: {
    DASHBOARD: "Genel Görünüm",
    HOME: "Ana Sayfa",
    SALES_MANAGEMENT: "Satış Yönetimi",
    ALL_SALES: "Tüm Satışlar",
    NEW_SALE: "Yeni Satış",
    QR_SALE: "QR Kod İle Satış Oluştur",
    SALE_DETAILS: "Satış Detayı",
    WALLET_MANAGEMENT: "Cüzdan Yönetimi",
    WALLETS: "Cüzdanlar",
    WITHDRAWALS: "Para Çekme İşlemleri",
    PERSONNEL_MANAGEMENT: "Personel Yönetimit",
    PERSONNEL: "Tüm Personel",
    NEW_PERSONNEL: "Yeni Personel",
    USERS: "Kullanıcılar",
    PROFILE: "Profil",
    COMPANY: "Firma Ayarları",
    SETTINGS: "Genel Ayarlar",
    ROLES: "Roller",
    LOGS: "Loglar",
    LOGOUT: "Çıkış",
    CHANGE_PASSWORD: "Şifre Değiştir"
  },
  FORM: {
    ID: "Id",
    YES: "Evet",
    NO: "Hayir",
    OK: "Tamam",
    TRY_AGAIN: "Lütfen tekrar deneyin.",
    SAVE: "Kaydet",
    UPDATE: "Güncelle",
    SUBMIT: "Gönder",
    CANCEL: "İptal",
    CONFIRM: "Onaylıyorum",
    EMAIL: "E-Posta",
    FIRST_NAME: "Ad",
    LAST_NAME: "Soyad",
    FULL_NAME: "Ad soyad",
    PASSWORD: "Parola",
    PASSWORD_CONFIRM: "Parola Tekrar",
    PHONE: "Telefon",
    LOGIN_WITH_GOOGLE: "Google İki Adımlı Doğrulama İle Giriş Yapın",
    GOOGLE_AUTHENTICATOR: "Google Authenticator",
    GOOGLE_SCAN_MESSAGE1: "Karekodu",
    GOOGLE_SCAN_MESSAGE2: "ile tarayın",
    APP: "app",
    CLICK: "Tıklayın",
    WHEN_DONE: "Onaylandığında",
    PHONE_OR_EMAIL: "Telefon veya Eposta",
    CONFIRM_PASSWORD: "Parolayı Doğrulayın",
    USERNAME: "Kullanıcı adı",
    SUCCESS_TITLE: "İşlem Başarılı",
    SUBMITTED: " başarıyla kaydedildi",
    UPDATED: " başarıyla güncellendi",
    DELETED: " başarıyla silindi",
    ERROR_TITLE: "Hata!",
    ERROR: "Bir Hata Oluştu.",
    PLEASE_SELECT: "Seçiniz",
    PAYMENT_UNIT: "Ödeme Birimi",
    POS_NUMBER: "Pos Numarası",
    SELECT_A_PAYMENT_UNIT: "Ödeme birimi seçiniz",
    SELECT_A_CURRENCY_UNIT: "Para Birimini Seçiniz"
  },
  AUTH: {
    GENERAL: {
      OR: "Ya da",
      SUBMIT_BUTTON: "Gönder",
      NO_ACCOUNT: "Hesabınız yok mu?",
      SIGNUP_BUTTON: "Kayıt ol",
      FORGOT_BUTTON: "Parolamı Unuttum",
      BACK_BUTTON: "Geri",
      PRIVACY: "Gizlilik",
      LEGAL: "Yasal",
      CONTACT: "İletişim"
    },
    LOGIN: {
      TITLE: "Giriş",
      BUTTON: "Giriş",
      WELCOME: "Hoş Geldiniz",
      NEW_HERE: "Yeni misiniz?",
      CREATE_ACCOUNT: "Yeni Hesap Oluştur",
      LOGIN: "Giriş Yap",
      OTP_VERIFICATION: "OTP Doğrulaması",
      TWO_FA: "2 Aşamalı Doğrulama",
      TWO_FA_GOOGLE: "Google ile 2 Aşamalı Doğrulama",
      TWO_FA_MESSAGE:
        "Lütfen giriş yapmak için telefonunuza ya da elektronik posta adresinize gelen doğrulama kodunu giriniz",
      TWO_FA_GOOGLE_MESSAGE:
        "Lütfen giriş yapmak için Google Authentıcator uygulamasından alacağınız doğrulama kodunu giriniz",
      VERIFICATION_CODE: "Doğrulama Kodunu Giriniz",
      SEND_AGAIN: "Tekrar Gönder",
      LOADING_MESSAGE_TITLE: "Biraz Bekleteceğiz",
      LOADING_MESSAGE_SUBTITLE: "Bilgilerinizi Çekiyoruz..."
    },
    FORGOT: {
      TITLE: "Parolanızı mı unuttunuz?",
      DESC: "Parolanızı sıfırlamak için e-posta adresinizi giriniz",
      SUCCESS: "Hesabınız başarıyla sıfırlandı."
    },
    REGISTER: {
      TITLE: "Kayıt ol",
      DESC: "Yeni hesap oluşturmak için bilgilerinizi giriniz",
      AGREEMENT_TEXT: "kabul ediyorum",
      AGREEMENT_LINK: "Şartları ve Koşulları",
      SUCCESS: "Hesabınız başarıyla kaydedildi."
    },
    VALIDATION: {
      INVALID: "{name} geçersiz",
      REQUIRED: "{name} gerekli alandır",
      MIN_LENGTH: "{name} en az {min} olmalıdır",
      AGREEMENT_REQUIRED: "Şartlar ve Koşulların kabul edilmesi gerekmektedir",
      NOT_FOUND: "İstenen {name} bulunamadı",
      INVALID_LOGIN: "Giriş bilgisi yanlıştır",
      REQUIRED_FIELD: "Zorunlu alan",
      MIN_LENGTH_FIELD: "En az karakter:",
      MAX_LENGTH_FIELD: "En fazla karakter:",
      INVALID_FIELD: "Alan geçersiz",
      PASSWORD_DONT_MATCH: "Şifreler uyuşmuyor."
    }
  },
  LIST: {
    CREATED_AT: "Kayıt",
    UPDATED_AT: "Son güncelleme"
  },
  USERS: {
    DELETED_USERS: "Silinmiş Kullanıcılar",
    NEW_USER: "Yeni Kullanıcı"
  },
  COMPANY: {
    COMPANY: "Firma",
    USER: "Kullanıcı",
    TITLE: "Ünvan",
    TAX_ADMINISTRATION: "Vergi Dairesi",
    TAX_CITY: "Vergi Dairesi Şehir",
    TAX_NUMBER: "Vergi Numarası",
    QUICK_SALE: "Hızlı Satış",
    MAIL_INFO_MESSAGE:
      "Açılan Kullanıcı kimlik bilgileri bu adrese gönderilecektir."
  },
  PAGES: {
    SALES_MANAGEMENT: {
      ORDER_DETAILS: {
        SALE_DETAILS: "Satış Detayları",
        DELETE_ORDER: "Satışı Sil",
        ORDER_INFOS: "Sipariş Bilgileri",
        ORDER_ID: "Sipariş Kimliği"
      },
      LINK_SALES: {
        NEW_SALE: "Link İle Satış Yap",
        ORDER_INFO: "Sipariş Bilgileri",
        ORDER_AMOUNT: "Sipariş Tutarı",
        COMMISSION_RATE: "Komisyon Oranı",
        DEDUCT_COMMISSION: "Komisyon oranı sipariş tutarından düşülsün mü?",
        COMMISSION_AMOUNT: "Komisyon Tutarı",
        ORDER_TOTAL: "Toplam Sirapiş Tutarı",
        CREATE_LINK: "Link Oluştur",
        ORDER_IDENTITY: "Sipariş Kimliği",
        TOTAL_AMOUNT: "Toplam Tutar",
        TOTAL_QUANTITY: "Toplam Miktar",
        WALLET: "Cüzdan",
        TRANSACTION_STATUS: "İşlem Durumu",
        TRANSACTION_SUCCESS: "İşlem Başarılı",
        TRANSACTION_ERROR: "İşlem Başarısız"
      },
      LIST: {
        ORDER_ID: "Sipariş No",
        PRICE: "Fiyat",
        COMMISSION: "Komisyon",
        TOTAL: "Toplam",
        STATUS: "Durum",
        DATE: "Tarih",
        SALE: "Satış",
        PENDING: "Beklemede",
        COMPLETED: "Tamamlandı",
        PROCESSING: "İşleniyor",
        FAILED: "İşlenemedi",
        CANCELLED: "İptal Edildi",
        NOT_ENOUGH: "Eksik Ödeme"
      },
      QR_SALE: {
        NEW_SALE_WITH_QR_CODE: {
          TITLE: "QR Kod İle Satış Yap",
          CUSTOMER_INFO: "Müşteri Bilgileri",
          SUCCESS_TITLE: "Ödemeniz Alınmıştır.",
          SUCCESS_SUBTITLE: "Teşekkür ederiz.",
          ERROR_SUBTITLE: "Lütfen tekrar deneyın.",
          SCAN_QR_CODE: "QR Kod Tarama",
          MIRACLE_CUSTOMER: "Miracle Müşterisi",
          NOT_MIRACLE_CUSTOMER: "Miracle Müşt. Değil",
          AN_ERROR_HAS_OCCURRED: "Bir hata oluştu.",
          TRY_AGAIN: "Tekrar Dene",
          ALL_ORDERS: "Tüm Siparişler",
          NEW_ORDER: "Yeni Satış Oluştur"
        },
        ORDER_COMPLETED: "Ödeme alındı.",
        ORDER_AMOUNT_IS: "İşlem tutarı",
        MISSING_ORDER_PAYMENT: "Eksik ödeme yapıldı.",
        ORDER_CRYPTO_AMOUNT_IS: "Kripto Tutarı",
        nexusCreatePaymentWarning:
          "Ödeme yaptıysanız lütfen işlem sonucu gelene kadar bekleyiniz.",
        deleteWalletAddress: "Cüzdan Adresi Sil",
        active_wallet_address_not_found_please_try_again_later:
          "Aktif bir cüzdan adresi bulunamadı. Lütfen daha sonra tekrar deneyiniz."
      },
      PROFILE: {
        PROFILE_PAGE: "Profil Sayfası"
      }
    },
    DASHBOARD: {
      CRYPTO: "Kripto",
      CURRENCY: "Para Birimi",
      LATEST_TRANSACTIONS: "Son İşlemler",
      CRYPTO_AMOUNT: "Tutar"
    }
  },
  ECOMMERCE: {
    COMMON: {
      SELECTED_RECORDS_COUNT: "Seçili kayıt adedi: ",
      ALL: "HEPSİ",
      SUSPENDED: "Durduruldu",
      ACTIVE: "Aktif",
      FILTER: "Filtre",
      BY_STATUS: "Duruma göre",
      BY_TYPE: "Tipe göre",
      BUSINESS: "İş",
      INDIVIDUAL: "Bireysel",
      SEARCH: "Arama",
      IN_ALL_FIELDS: "tüm alanlarda"
    },
    ECOMMERCE: "eTicaret",
    CUSTOMERS: {
      CUSTOMERS: "Müşteriler",
      CUSTOMERS_LIST: "Müşteri List",
      NEW_CUSTOMER: "Yeni Müşteri",
      DELETE_CUSTOMER_SIMPLE: {
        TITLE: "Müşteriyi Sil",
        DESCRIPTION: "Bu müşteriyi kalıcı olarak silmek istiyor musunuz?",
        WAIT_DESCRIPTION: "Müşteri siliniyor...",
        MESSAGE: "Müşteri silindi"
      },
      DELETE_CUSTOMER_MULTY: {
        TITLE: "Müşterileri Sil",
        DESCRIPTION: "Seçili müşterileri kalıcı olarak silmek istiyor musunuz?",
        WAIT_DESCRIPTION: "Müşteriler siliniyor...",
        MESSAGE: "Seçili müşteriler silindi"
      },
      UPDATE_STATUS: {
        TITLE: "Seçili müsteriler için durum güncellendi",
        MESSAGE: "Seçili müşterilerin durumları başarıyla güncellendi"
      },
      EDIT: {
        UPDATE_MESSAGE: "Müşteri güncellendi",
        ADD_MESSAGE: "Müşteri kaydı oluşturuldu"
      }
    }
  },
  VALIDATION: {
    NUMERIC: "Sadece numerik değerler kabul edilir.",
    MIN_VALUE: "{name} minimum değer {min} olmalı",
    ONLY_INTEGERS: "Yalnızca tamsayılar geçerlidir."
  },
  COMMON: {
    ACTIVATE: "Aktif Et",
    MAKE_PASSIVE: "Pasif Et",
    NO_DATA_AVAILABLE: "Veri bulunamadı.",
    are_you_sure_you_want_to_perform:
      "Bu işlemi gerçekleştirmek istediğinizden emin misiniz?",
    operation_performed: "İşlem başarıyla gerçekleştirildi."
  },
  cart: "Sepet",
  price: "Fiyat",
  total: "Toplam",
  pay_with_crypto: "Kripto İle Öde",
  unexpected_error: "Bilinmeyen bir hata oluştu."
};
