import Api from "@/services/api";
// import Moment from "moment";
import JwtService from "@/services/jwt";
import router from "../../router";
import { baseUrl } from "../../config/apiBaseUrl";

const authenticationTokenEndpoint = `${baseUrl}authentication_token`;
const main = {
  state: () => ({
    me: {},
    company: {},
    company_id: null,
    order: {},
    order_merchant_id: null
  }),
  mutations: {
    SET_AUTH(state, data) {
      JwtService.saveAuthToken(data.token);
      Api.setHeader();
    },
    PURGE_AUTH(state) {
      state.order = {};
      // localStorage.clear();
      JwtService.clearAuth();
    },
    SET_ME(state, data) {
      state.me = data;
      state.company = data.parent;
      state.company_id = data.parent.id;
    },
    SET_ORDER(state, order) {
      state.order = order;
      state.order_merchant_id = order.merchantId;
    }
  },
  actions: {
    async authenticationToken({ commit }, credentials) {
      try {
        const res = await Api.post(authenticationTokenEndpoint, credentials);
        commit("SET_AUTH", res.data);
        return res;
      } catch (error) {
        console.log("authenticationToken error: ", error);
        return error;
      }
    },
    verifyAuth({ commit }) {
      if (JwtService.getAuthToken()) {
        Api.setHeader();
        Api.get("/me")
          .then(({ data }) => {
            commit("SET_ME", data);
          })
          .catch(() => {
            commit("PURGE_AUTH");
          });
      } else {
        commit("PURGE_AUTH");
      }
    },
    async createOrder({ commit }, payload) {
      payload.price = payload.price.toString();
      payload.fee = payload.fee.toString();
      try {
        const resp = await Api.post("company-orders", payload);
        commit("SET_ORDER", resp.data);
        router.push("/qr-sale");
        return resp;
      } catch (error) {
        console.log(error.response);
        return error;
      }
    }
  },
  getters: {
    company(state) {
      return state?.company || null;
    },
    company_id(state) {
      return state.company_id || null;
    },
    ORDER_MERCHANT_ID(state) {
      return state.order_merchant_id;
    }
  },
  namespaced: true
};

export default main;
