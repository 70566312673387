import Vue from "vue";
import Vuex from "vuex";

import main from "./modules/main";
import htmlClass from "./modules/htmlclass";
import config from "./modules/config";
import breadcrumbs from "./modules/breadcrumbs";
import profile from "./modules/profile";

import orders from "./modules/orders";
import wallets from "./modules/wallet";
import company from "./modules/company";
import user from "./modules/user";
import paymentunits from "./modules/paymentunits";
import dashboard from "./modules/dashboard";
import calculator from "./modules/calculator";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    main,
    htmlClass: htmlClass,
    config: config,
    breadcrumbs: breadcrumbs,
    profile: profile,
    orders: orders,
    wallets: wallets,
    company: company,
    user: user,
    paymentunits,
    dashboard,
    calculator
  }
});
