import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store";
import Api from "@/services/api";
import { RESET_LAYOUT_CONFIG } from "@/store/modules/config";

// Santry
/* import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing"; */

Vue.config.productionTip = false;

// Global 3rd party plugins
import "popper.js";
import "tooltip.js";
import PerfectScrollbar from "perfect-scrollbar";
window.PerfectScrollbar = PerfectScrollbar;
import ClipboardJS from "clipboard";
window.ClipboardJS = ClipboardJS;

// Vue 3rd party plugins
import i18n from "@/plugins/vue-i18n";
import "@/plugins/portal-vue";
import "@/plugins/bootstrap-vue";
import "@/plugins/perfect-scrollbar";
import "@/plugins/highlight-js";
import "@/plugins/inline-svg";
import "@/plugins/apexcharts";
import "@/plugins/metronic";
import "@mdi/font/css/materialdesignicons.css";
import "@/plugins/formvalidation";
import "@/plugins/datatable";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

Vue.use(require("vue-moment"));

// API service init
Api.init();

// Remove this to disable mock API
// Mock.init();

router.beforeEach((to, from, next) => {
  // Ensure we checked auth before each page load.
  Promise.all([store.dispatch("main/verifyAuth")]).then(next);

  // reset config to initial state
  store.dispatch(RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

// Santry
/* Sentry.init({
  Vue,
  dsn: "http://9885768ec37b4d32bdab60c4589af318@sentry.ofinansdev.com:9000/6",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: [
        "http://localhost:8080",
        "https://miraclepay.dev.ofinansdev.com/",
        /^\//
      ]
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
}); */

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
