import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import { ofinansBaseApiUrl } from "../config/apiBaseUrl";

const oFinansAxios = axios.create({
  baseURL: ofinansBaseApiUrl
});

/**
 * Service to call HTTP request via Axios
 */
const ApiOfinans = {
  init() {
    Vue.use(VueAxios, oFinansAxios);
    oFinansAxios.defaults.baseURL = ofinansBaseApiUrl;
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    oFinansAxios.defaults.headers.common["Authorization"] =
      `Bearer ` + localStorage.getItem("ofinans_token");
  },

  query(resource, params) {
    return oFinansAxios.get(resource, params).catch(error => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    return oFinansAxios.get(`${resource}/${slug}`).catch(error => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return oFinansAxios.post(`${resource}`, params);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return oFinansAxios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return oFinansAxios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    return oFinansAxios.delete(resource).catch(error => {
      throw new Error(`[RWV] ApiService ${error}`);
    });
  }
};

export default ApiOfinans;
