import Api from "../../services/api";

const company = {
  state: () => ({
    MAIN_COMPANY: null,
    SELECTED_COMPANY: null
  }),
  mutations: {
    SET_MAIN_COMPANY(state, company) {
      state.MAIN_COMPANY = company;
    },
    SET_SELECTED_COMPANY(state, company) {
      state.SELECTED_COMPANY = company;
    },
    CLEAR_SELECTED_COMPANY(state) {
      state.SELECTED_COMPANY = {};
    }
  },
  actions: {
    async SET_MAIN_COMPANY({ commit }, company) {
      if (!this.getters["company/MAIN_COMPANY_ID"]) {
        commit("SET_MAIN_COMPANY", company);
      }
    },
    async GET_COMPANY(state, companyId) {
      return await Api.get("companies/" + companyId);
    },
    async GET_SUB_COMPANIES({ state }) {
      const id = state.MAIN_COMPANY.id;
      const subCompanies = await Api.get("companies/" + id + "/companies");
      const deletedCompaniesCount = await Api.get(
        "companies/" + id + "/deleted-companies-count"
      );
      return {
        subCompanies: subCompanies.data,
        deletedSubCompaniesCount: deletedCompaniesCount.data
      };
    },
    async GET_USERS({ state }, companyId = undefined) {
      const id = companyId ? companyId : state.MAIN_COMPANY.id;
      const users = await Api.get("companies/" + id + "/users");
      const deletedUsersCount = await Api.get(
        "companies/" + id + "/deleted-users-count"
      );
      return {
        users: users.data,
        deletedUsersCount: deletedUsersCount.data
      };
    },
    async GET_DELETED_COMPANIES({ state }) {
      const id = state.MAIN_COMPANY.id;
      const deletedCompanies = await Api.get(
        "companies/" + id + "/deleted-companies"
      );
      return deletedCompanies.data;
    },
    async GET_DELETED_USERS({ state }) {
      const id = state.MAIN_COMPANY.id;
      const deletedUsers = await Api.get("companies/" + id + "/deleted-users");
      return deletedUsers.data;
    },
    async DELETE_COMPANY(state, deleteId) {
      return await Api.delete("companies/" + deleteId);
    },
    async UNDELETE_COMPANY({ state }, undeleteId) {
      const id = state.MAIN_COMPANY.id;
      return await Api.put(
        "companies/" + id + "/undelete-company/" + undeleteId,
        { headers: { "Content-Type": "application/json", Accept: "*/*" } }
      );
    },
    async UNDELETE_USER({ state }, undeleteId) {
      const id = state.MAIN_COMPANY.id;
      return await Api.put("companies/" + id + "/undelete-user/" + undeleteId, {
        headers: { "Content-Type": "application/json", Accept: "*/*" }
      });
    },
    async GET_COMPANY_WALLETS({ state }) {
      const id = state.MAIN_COMPANY.id;
      const wallets = await Api.get("companies/" + id + "/company-wallets");
      return wallets.data;
    },
    SET_SELECTED_COMPANY({ commit }, company) {
      commit("SET_SELECTED_COMPANY", company);
    },
    CLEAR_SELECTED_COMPANY({ commit }) {
      commit("CLEAR_SELECTED_COMPANY");
    },
    async TOGGLE_COMPANY_ACTIVE_STATUS(state, { companyId, isActive = true }) {
      return await Api.put("companies/" + companyId, { isActive });
    }
  },
  getters: {
    MAIN_COMPANY(state) {
      return state.MAIN_COMPANY;
    },
    MAIN_COMPANY_ID(state) {
      return state.MAIN_COMPANY ? state.MAIN_COMPANY.id : null;
    },
    SELECTED_COMPANY(state) {
      return state.SELECTED_COMPANY;
    },
    SELECTED_COMPANY_ID(state) {
      return state.SELECTED_COMPANY ? state.SELECTED_COMPANY.id : null;
    }
  },
  namespaced: true
};

export default company;
